import React from 'react'

function OneDrivePicker () {
    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });
    
    // let params = new URL(document.location.toString()).searchParams;
    // let token = params.get("token");
    // let titleString = params.get("title");
    // let timeString = params.get("time");
    let src = `/picker-one-drive.html`;
    
    console.log(src);
    return (
          <iframe
            src={src}
            title="Local HTML"
            style={{ width: '100vw', height: '100vh', border: 'none' }}
          />
      );

}


export default OneDrivePicker